/* eslint-disable @typescript-eslint/no-useless-constructor */
import { Injectable } from '@angular/core';
import { LogMixpanelEventUseCase } from 'src/app/core/usecases/analytics/log-mixpanel-event.usecase';
import { BasePresenter } from '../../base/base.presenter';
import { SurveyFormBannerSideEffect } from './survey-form-banner.side-effect';
import { SurveyFormBannerViewEvent } from './survey-form-banner.view-event';
import { SurveyFormBannerViewState } from './survey-form-banner.view-state';

@Injectable({ providedIn: 'root' })
export class SurveyFormBannerPresenter extends BasePresenter<
  SurveyFormBannerViewState,
  SurveyFormBannerViewEvent,
  SurveyFormBannerSideEffect
> {
  private bannerContent = {
    title: 'SURVEY_BANNER.TITLE',
    description: 'SURVEY_BANNER.DESCRIPTION',
    buttonTitle: 'SURVEY_BANNER.CTA',
    formLink: 'https://2hlx9cbqbxc.typeform.com/Taageroman',
    iconName: 'oman-flag',
  };

  constructor(private _logMixpanelEventUseCase: LogMixpanelEventUseCase) {
    super();
  }

  protected defaultViewState(): SurveyFormBannerViewState {
    return {
      content: {
        title: '',
        description: '',
        buttonTitle: '',
        formLink: '',
        iconName: '',
      },
    };
  }

  onViewEvent(event: SurveyFormBannerViewEvent): void {
    switch (event.type) {
      case 'ClickJoinNow': {
        this.emitSideEffect({ type: 'NavigateToForm', link: this.viewState.content.formLink });
        this._logMixpanelEventUseCase.execute({ eventName: 'ux_interviews_form_join_now_clicked' });
        break;
      }
      case 'Init': {
        this.merge({
          content: this.bannerContent,
        });
      }
    }
  }
}
